import { GitHub, Instagram, LinkedIn, Twitter } from '@mui/icons-material'
import {
  Box,
  Chip,
  Fade,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { SocialLinks } from 'helpers/config'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AnimatedText from 'react-animated-text-content'
import TextTransition, { presets } from 'react-text-transition'
import { Colors, FontSizes, FontWeights } from 'styles'
import { useQuery } from 'react-query'
import axios from 'axios'
import { VscRepoForked, VscStarFull } from 'react-icons/vsc'
import { SiTypescript, SiJavascript } from 'react-icons/si'
import { NameDisplay, SocialRow, TechDisplay, Header } from './Home.view.styles'
import { RepoTypes } from './Home.view.types'
import { WorkTimeline } from './components/Timeline'

export const HomeView: React.FC = () => {
  const [index, setIndex] = useState(0)
  const [repos, setRepos] = useState<RepoTypes[] | 'error' | undefined>()

  useQuery(
    'getPinnedRepos',
    () =>
      axios
        .get(`https://gh-pinned-repos.egoist.dev/?username=makis-san`)
        .then((data) => data.data),
    { onSuccess: (data) => setRepos(data), onError: () => setRepos('error') }
  )

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((cur) => cur + 1), 2500)
    return () => clearTimeout(intervalId)
  }, [])

  const languageIcon = useCallback(
    (lang: string) => {
      if (lang === 'TypeScript') {
        return <SiTypescript size='1rem' />
      }
      if (lang === 'JavaScript') {
        return <SiJavascript size='1rem' />
      }
      return undefined
    },
    [SiJavascript, SiTypescript]
  )

  const TEXTS = useMemo(
    () => [
      'React',
      'Typescript',
      'SQL',
      'Web',
      'NextJS',
      'FullStack',
      'NodeJS',
      'Cloud',
      'MongoDB',
      'Mobile',
      'React Native'
    ],
    [index]
  )

  return (
    <Box p={4}>
      <Typography
        align='center'
        fontWeight={FontWeights.extraBold}
        fontSize={FontSizes.smaller}
      >
        Welcome
      </Typography>
      <Header>
        <NameDisplay>
          <Box>
            {useMemo(
              () => (
                <>
                  <AnimatedText
                    type='chars'
                    animationType='wave'
                    interval={0.06}
                    duration={0.5}
                    includeWhiteSpaces
                    threshold={0.1}
                  >
                    Santiago
                  </AnimatedText>
                  <AnimatedText
                    type='chars'
                    animationType='wave'
                    interval={0.06}
                    duration={0.5}
                    includeWhiteSpaces
                    threshold={0.1}
                  >
                    Souza
                  </AnimatedText>
                </>
              ),
              []
            )}
          </Box>

          <Fade in timeout={1000}>
            <SocialRow>
              <IconButton
                onClick={() => {
                  window.location.href = SocialLinks.twitter
                }}
              >
                <Twitter />
              </IconButton>
              <IconButton
                onClick={() => {
                  window.location.href = SocialLinks.linkedin
                }}
              >
                <LinkedIn />
              </IconButton>
              <IconButton
                onClick={() => {
                  window.location.href = SocialLinks.github
                }}
              >
                <GitHub />
              </IconButton>
              {/* <IconButton>
                <YouTube />
              </IconButton> */}
              <IconButton
                onClick={() => {
                  window.location.href = SocialLinks.instagram
                }}
              >
                <Instagram />
              </IconButton>
            </SocialRow>
          </Fade>
        </NameDisplay>
        <TechDisplay>
          <TextTransition
            className='MuiTypography-root tech'
            text={TEXTS[index % TEXTS.length]}
            springConfig={presets.wobbly}
          />
          <Typography>Developer</Typography>
        </TechDisplay>
      </Header>
      <Typography textAlign='center' fontWeight={FontWeights.extraBold}>
        Work Timeline
      </Typography>
      <WorkTimeline />
      <Typography textAlign='center' mb={4} fontWeight={FontWeights.extraBold}>
        Open Source Contributions
      </Typography>
      <Stack
        display='flex'
        mb={8}
        justifyContent='space-evenly'
        gap={2}
        flexWrap='wrap'
        direction='row'
      >
        {repos && repos !== 'error'
          ? repos.map((repo) => (
              <Paper
                sx={{
                  flex: '1 1 calc(33.33% - 20px)',
                  flexWrap: 'wrap',
                  borderRadius: 1,
                  padding: 2,
                  borderBottom: `0.3rem solid ${repo.languageColor}`
                }}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={2}
                  pb={1}
                  sx={{
                    borderBottom: `0.05rem solid ${Colors.greyishBlue}`
                  }}
                >
                  <Link
                    href={repo.link}
                    sx={{
                      color: 'white',
                      textDecoration: 'none',
                      fontWeight: FontWeights.extraBold
                    }}
                    fontSize={FontSizes.smaller}
                  >
                    {repo.repo}
                  </Link>
                  <Stack direction='row' spacing={0.5}>
                    <Chip
                      icon={<VscRepoForked size='1rem' />}
                      label={
                        <Typography
                          sx={{
                            fontSize: '.85rem'
                          }}
                        >
                          {repo.forks}
                        </Typography>
                      }
                    />
                    <Chip
                      icon={<VscStarFull size='1rem' />}
                      label={
                        <Typography
                          sx={{
                            fontSize: '.85rem'
                          }}
                        >
                          {repo.stars}
                        </Typography>
                      }
                    />
                    {languageIcon(repo.language) && (
                      <Chip
                        sx={{ background: repo.languageColor }}
                        label={languageIcon(repo.language)}
                      />
                    )}
                  </Stack>
                </Stack>
                <Typography mt={1} fontSize='0.95rem'>
                  {repo.description}
                </Typography>
              </Paper>
            ))
          : null}
      </Stack>
      <Box textAlign='center' fontSize={FontSizes.smaller}>
        {useMemo(
          () => (
            <>
              <AnimatedText duration={0.5} interval={0.05} type='chars'>
                - more to come, keep in touch -
              </AnimatedText>
              <Link href={SocialLinks.twitter} style={{ fontSize: 12 }}>
                <AnimatedText duration={0.5} interval={0.05} type='chars'>
                  @elpatino_
                </AnimatedText>
              </Link>
            </>
          ),
          []
        )}
      </Box>
    </Box>
  )
}
