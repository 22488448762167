import { BackdropComponent } from 'components'
import { Layout } from 'components/layout/Layout'
import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { HomeView } from 'views'

export const Router: React.FC = () => {
  const fallbackRoute = (
    <Navigate to='404' state={{ useAlternativeLayout: true }} />
  )

  return (
    <Suspense fallback={<BackdropComponent open={false} />}>
      <Layout>
        <BackdropComponent open={false} />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomeView />} />
            <Route path='*' element={fallbackRoute} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </Suspense>
  )
}
