import styled from 'styled-components'
import { Colors, FontSizes, FontWeights, to } from 'styles'

export const TechDisplay = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  align-items: flex-start;
  height: max-content;
  ${to.sm} {
    border-left: none;
    margin-top: 0rem;
  }
  width: 100%;
  .tech {
    color: ${Colors.primary};
  }

  .MuiTypography-root {
    font-weight: ${FontWeights.extraBold};
    font-size: ${FontSizes.h4};
    text-align: right;
    line-height: 3.5rem;
    margin-left: 1rem;
    ${to.sm} {
      text-align: left;
      margin-left: 0rem;
    }
  }
`

export const NameDisplay = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${FontSizes.h4};
  text-align: left;
  line-height: 3.5rem;
  padding-right: 1rem;
  width: 100%;
  align-items: flex-end;

  ${to.sm} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: right;
    padding-right: 0rem;
  }
`

export const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0;
  svg {
    color: ${Colors.primary};
    font-size: ${FontSizes.normal};
  }
  ${to.sm} {
    flex-direction: column;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  ${to.sm} {
    flex-direction: column;
  }
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 50vh;
`
