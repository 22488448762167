import styled from 'styled-components'
import { Box } from '@mui/material'
import { Colors, FontSizes, FontWeights, from } from 'styles'
import { LayoutContainerPropTypes } from './Layout.types'

export const LayoutStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.default};
`
export const LayoutHeaderStyles = styled.header`
  position: relative;
  z-index: 5;

  min-height: 8.1rem;
  padding: 0 2rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;

  background-color: ${Colors.white};
  box-shadow: 0 0.3rem 0.6rem #00000015;

  .MuiIconButton-root {
    svg {
      color: ${Colors.dark};
      font-size: ${FontSizes.h5};
    }
  }

  ${from.md} {
    display: flex;

    .MuiIconButton-root {
      height: 4rem;
      padding: 0;
      border-radius: 0.5rem;
      display: flex;

      svg:first-child {
        font-size: 3.4rem;
      }

      svg:not(:first-child) {
        color: ${Colors.dark};
      }
    }
  }
`

export const LogoBoxStyles = styled(Box)`
  svg {
    min-width: 7rem;
  }
`

export const FilterBoxStyles = styled(Box)`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .MuiIconButton-root {
    padding: 0;
    border-radius: 0;
    height: 100%;
  }

  ${from.md} {
    display: none;
  }
`

export const TitleBoxStyles = styled(Box)`
  text-align: center;
  h4 {
    font-weight: ${FontWeights.bold};
    color: ${Colors.dark};
    font-size: ${FontSizes.normalPlus};
  }
  ${from.md} {
    display: none;
  }
`

export const LayoutWrapperStyles = styled.div`
  display: flex;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`

export const HeaderButtonsStyles = styled(Box)`
  display: none;
  ${from.md} {
    min-width: min-content;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: flex-end;
  }
`

export const LayoutContainerStyles = styled.main<LayoutContainerPropTypes>`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  height: 100%;
  /* max-height: calc(100% - 6rem); */

  ${from.md} {
    ${({ useAlternativeLayout }) =>
      useAlternativeLayout ? `padding: 0;` : `padding: 4rem 3rem 2rem 3rem;`}

    max-height: 100%;
    padding-bottom: 8rem;
  }

  ${({ hideOverflowY }) =>
    hideOverflowY &&
    `

    ${from.md} {
      padding-bottom: 0;
      overflow-y: hidden;
    }
  `}
`

export const UserBoxStyles = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiTypography-root {
    padding: 0 0.5rem;
    font-size: ${FontSizes.normal};
    font-weight: ${FontWeights.normal};
    color: ${Colors.blackAlt};
  }

  .MuiButtonBase-root {
    &:hover {
      background: none;
    }
  }
`
