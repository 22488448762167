import React, { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import { breakpoints } from '../tools/breakpoints'
import { Colors } from '../tools/colors'
import { MuiTheme } from './MuiTheme'
import { Theme } from './theme.types'

export const StyledThemeWrapper = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={MuiTheme}>{children}</ThemeProvider>
)
