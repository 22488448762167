/* eslint-disable no-shadow */
export enum Colors {
  primary = '#88C0D0',
  primaryLight = '#81A1C1',
  primaryDark = '#5E81AC',
  secondary = '#5E81AC',
  secondaryLight = '#7700E6',
  secondaryDark = '#420080',
  light = '#818A91',
  dark = '#00034F',
  success = '#0FBE00',
  successLight = '#B2FFAC',
  successDark = '#0C8C00',
  danger = '#FF4D6F',
  dangerLight = '#FF8CA1',
  dangerDark = '#D9415D',
  info = '#007FFF',
  infoLight = '#8CC6FF',
  infoDark = '#0060BF',
  warning = '#F7D424',
  warningLight = '#FFF1AC',
  warningDark = '#D9BA21',
  orange = '#FF7B48',
  orangeLight = '#FFD0A0',
  orangeDark = '#FF4800',
  blue = '#CCDAE8',
  pastelBlue = '#8CC4fC',
  greyishBlue = '#DEEEFF',
  darkBlue = '#040353',
  lightBlue = '#D9ECFF',
  lighterBlue = '#F4F9FF',
  white = '#FFFFFF',
  black = '#2E3440  ',
  blackAlt = '#2b2b2b',
  greyBg = '#F8F7F5',
  blueFeide = '#285CA9',
  lightGrey = '#DDDDDD',
  lighterGrey = '#E3E3E3',
  lightestGrey = '#F4F4F4',
  progressLightGreen = '#D2FFCE',
  progressGreen = '#B6EBB2',
  progressDarkGreen = '#57D14D',
  lightPink = '#EDD9FF'
}

export type ColorsTypes = typeof Colors
