/* eslint-disable no-shadow */
import Montserrat from 'assets/fonts/montserrat/Montserrat-Regular.ttf'

export enum FontSizes {
  smallest = '0.8rem',
  smaller = '1.2rem',
  small = '1.4rem',
  normal = '1.6rem',
  normalPlus = '1.8rem',
  h6 = '2.1rem',
  h5 = '2.8rem',
  h4 = '3.7rem',
  h3 = '5.0rem',
  h2 = '6.7rem'
}

export enum FontWeights {
  normal = 400,
  bold = 600,
  extraBold = 800,
  superBold = 900
}

export enum FontFamilies {
  Montserrat = 'Montserrat'
}

export const genericTypography = (): string => `
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    src: local('Montserrat'), url(${Montserrat}) format('truetype');
  }
`
