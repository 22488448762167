import React from 'react'
import { Router } from 'router'
import { ThemeWrapper } from 'styles'
import { QueryClient, QueryClientProvider } from 'react-query'

export default () => (
  <React.StrictMode>
    <ThemeWrapper>
      <QueryClientProvider client={new QueryClient()}>
        <Router />
      </QueryClientProvider>
    </ThemeWrapper>
  </React.StrictMode>
)
