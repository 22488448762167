import { LightMode } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import React from 'react'
import {
  LayoutContainerStyles,
  LayoutStyles,
  LayoutWrapperStyles
} from './Layout.styles'

type Props = {
  children?: React.ReactNode
}
export const Layout = ({ children }: Props) => {
  return (
    <LayoutStyles>
      {/* <Stack>
        <Button variant='contained' color='primary'>
          <LightMode />
        </Button>
      </Stack> */}
      <LayoutWrapperStyles>
        <LayoutContainerStyles>{children}</LayoutContainerStyles>
      </LayoutWrapperStyles>
    </LayoutStyles>
  )
}
