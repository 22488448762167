import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { timelineItemClasses } from '@mui/lab'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import { Divider, Typography } from '@mui/material'
import { Colors, FontSizes, FontWeights } from 'styles'

export const WorkTimeline = () => {
  const data = [
    {
      date: 'Feb 2022',
      current: true,
      title: 'Eduplaytion - Norway',
      roles: ['Frontend Developer'],
      description:
        'Worked in the development of the Numetry Teacher Panel, Parent Panel, o and other internal applications'
    },
    {
      date: 'Sep 2021 - Jan 2022',
      title: 'GDS TEC - Brazil',
      roles: ['Web and Mobile Development Leader'],
      description:
        'Worked as the leader front-end and mobile developer in projects related to sports gambling and food shopping apps (similar to iFood and Uber Eats)'
    },
    {
      date: 'Jul 2021 - Jan 2022',
      title: 'Leap Studios - Brazil',
      roles: ['Creative Director'],
      description:
        'I was one of the founders and also the creative director. My main role was to organize the tasks between the teams (developers, writers, marketing, and design) and also to be in front of the negotiations happening between the company and the clients.'
    },
    {
      date: 'Mar 2020 - Nov 2020',
      title: 'Quacks Interatividade Digital - Brazil',
      roles: ['UI Designer', 'Frontend Developer', 'FullStack Developer'],
      description:
        'I was the main front-end developer and also worked on the backend. My role was to develop static and dynamic landing pages, e-commerce apps, and mobile apps, and also did participation on educational games (Aula Pratik)'
    }
  ]
  return (
    <Timeline position='alternate'>
      {data.map((job, jobIndex) => (
        <TimelineItem>
          <TimelineOppositeContent color='text.secondary'>
            <Typography fontSize={FontSizes.smaller}>{job.date}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color='primary' />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              fontWeight={FontWeights.bold}
              mb={2}
              color={Colors.primary}
            >
              {job.title}
            </Typography>
            <Typography fontSize='0.95rem'>{job.description}</Typography>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0
                }
              }}
            >
              <Typography
                fontSize={FontSizes.smaller}
                fontWeight={FontWeights.bold}
                color={Colors.primary}
              >
                Roles
              </Typography>
              {job.roles.reverse().map((role, index) => (
                <TimelineItem>
                  {jobIndex % 2 !== 0 && (
                    <TimelineContent sx={{ fontSize: '1rem' }}>
                      {role}
                    </TimelineContent>
                  )}
                  <TimelineSeparator>
                    {index + 1 === job.roles.length ? (
                      <TimelineDot color='primary' />
                    ) : (
                      <>
                        <TimelineDot color='primary' />
                        <TimelineConnector />
                      </>
                    )}
                  </TimelineSeparator>
                  {jobIndex % 2 === 0 && (
                    <TimelineContent sx={{ fontSize: '1rem' }}>
                      {role}
                    </TimelineContent>
                  )}
                </TimelineItem>
              ))}
            </Timeline>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
