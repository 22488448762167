import React, { PropsWithChildren, ReactElement } from 'react'
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import { Colors } from '../tools/colors'
import { MuiThemeTypes } from './theme.types'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headerTitle: React.CSSProperties
    headerSubtitle: React.CSSProperties
    inputLabel: React.CSSProperties
    institutionDescriptionTitle: React.CSSProperties
    institutionDescription: React.CSSProperties
    sectionTitle: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headerTitle?: React.CSSProperties
    headerSubtitle?: React.CSSProperties
    inputLabel?: React.CSSProperties
    institutionDescriptionTitle?: React.CSSProperties
    institutionDescription?: React.CSSProperties
    sectionTitle?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headerTitle: true
    headerSubtitle: true
    inputLabel: true
    institutionDescriptionTitle: true
    institutionDescription: true
    sectionTitle: true
  }
}

export const MuiTheme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),

    htmlFontSize: 10,
    fontWeightBold: 600,
    allVariants: {
      lineHeight: 1.2
    },
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2.5rem'
    },
    h3: {
      fontSize: '2.3rem'
    },
    subtitle1: {
      fontSize: '1.5rem'
    },
    subtitle2: {
      fontSize: '1.3rem'
    },
    body1: {
      fontSize: '1.5rem'
    },
    body2: {
      fontSize: '1.3rem'
    },
    caption: {
      fontSize: '1rem'
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: Colors.primary,
      light: Colors.primaryLight,
      dark: Colors.primaryDark
    },
    secondary: {
      main: Colors.secondary,
      light: Colors.secondaryLight,
      dark: Colors.secondaryDark
    },
    info: {
      main: Colors.info,
      light: Colors.infoLight,
      dark: Colors.infoDark
    },
    success: {
      main: Colors.success,
      light: Colors.successLight,
      dark: Colors.successDark
    },
    warning: {
      main: Colors.warning,
      light: Colors.warningLight,
      dark: Colors.warningDark
    },
    error: {
      main: Colors.danger,
      light: Colors.dangerLight,
      dark: Colors.dangerDark
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        style: {
          boxShadow: '0 0.6rem 2rem #00000015'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              boxShadow: '0 0.6rem 2rem #00000015',
              marginTop: 1
            }
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1440
    }
  }
})

export const MuiThemeWrapper: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
